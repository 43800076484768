<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-1">
			<div class="col-12 text-center">
				<div class="h2 text-muted py-1">
					{{ translate('choose_one_option') }}
				</div>
			</div>
		</div>
		<template
			v-if="!loading">
			<div class="row pt-1 pb-2">
				<div class="col" />
				<div class="col col-xs-12 mb-2">
					<ul
						class="list-group"
						:style="{ minWidth: '317px' }">
						<li class="list-group-item text-center">
							<img
								:src="accountFeeData.attributes.image"
								:style="{ maxWidth: ['xl'].includes(windowWidth) ? '223px' : '300px', marginTop: '1.88rem', marginBottom: '2rem' }"
								class="img-fluid mx-auto pointer">
							<div
								v-if="['sm', 'md'].includes(windowWidth)"
								class="row justify-content-center pt-4">
								<div class="col-auto pr-2 pl-0">
									<h1
										class="text-dark font-weight-bold mb-0 h2">
										{{ translate(accountFeeData.attributes.code_name) }}
									</h1>
								</div>
							</div>
							<div
								v-else>
								<div
									class="row justify-content-center pt-4 mt-4">
									<div class="col-auto">
										<h1
											class="text-dark font-weight-bold mb-0 h4">
											{{ translate(accountFeeData.attributes.code_name) }}
										</h1>
									</div>
								</div>
							</div>
							<div
								class="row justify-content-center pb-4 mb-4">
								<div class="col-auto pr-2 pl-0">
									<div
										class="col-auto text-primary h3 font-weight-bold"
										style="padding: 0; margin-bottom: 0; margin-top: 4.95rem;">
										{{ accountFeeData.attributes.price }}
									</div>
								</div>
							</div>
							<b-button
								:disabled="validating || !!preparing || showFreeProductsModal"
								:style="{ marginTop: '2.8rem' }"
								variant="primary"
								size="lg"
								block
								class="float-right"
								@click="prepareFeeStorage">
								<i
									v-if="validating && selectedPack === ''"
									class="fas  fa-spinner fa-spin mr-2" />
								{{ translate('select') }}
							</b-button>
						</li>
					</ul>
				</div>
				<div
					v-for="(item, id) in packsData"
					:key="id"
					class="col col-xs-12 mb-2">
					<ul
						class="list-group"
						:style="{ minWidth: '255px' }">
						<li class="list-group-item text-center">
							<img
								:src="item.attributes.packages[packagesStates[item.id]].img"
								:style="{ maxWidth: ['xl'].includes(windowWidth) ? '275px' : '300px' }"
								class="img-fluid mx-auto pointer">
							<div v-if="item.attributes.packages.length > 1">
								<b-col
									class="m-0 p-0"
									style="font-size: 0.8rem;">
									<p
										style="display:inline"
										class="mr-1">
										{{ translate('regular_option') }}
									</p>
									<switch-toggle
										v-model="toggleStates[item.id]"
										variant="success"
										pill
										class="pt-1"
										style="transform: scale(0.8);"
										@change="() => handlePackToggle(item.id)" />
									<p
										style="display:inline"
										class="ml-2">
										{{ translate('special_option') }}
									</p>
								</b-col>
							</div>
							<div
								v-if="['sm', 'md'].includes(windowWidth)"
								class="row justify-content-center pt-4">
								<div class="col-auto pr-2 pl-0">
									<h1
										class="text-dark font-weight-bold mb-0 h2">
										{{ translate(item.id + '_pack') }}
									</h1>
								</div>
								<div
									class="col-auto h5"
									style="padding: 0; margin-bottom: 0; margin-block: auto">
									{{ "(" + translate('bvs', { qty: item.attributes.packages[packagesStates[item.id]].bv }) + ")" }}
								</div>
							</div>
							<div
								v-else>
								<div
									class="row justify-content-center"
									:class="item.attributes.packages.length > 1 ? 'pt-2' : 'pt-4 mt-2'">
									<div class="col-auto">
										<h1
											class="text-dark font-weight-bold mb-0 h4">
											{{ translate(item.id + '_pack') }}
										</h1>
									</div>
								</div>
								<div
									class="row justify-content-center">
									<div
										class="col-auto h5"
										style="padding: 0; margin-bottom: 0; margin-block: auto">
										{{ "(" + translate('bvs', { qty: item.attributes.packages[packagesStates[item.id]].bv }) + ")" }}
									</div>
								</div>
							</div>
							<p>
								<a
									class="pointer text-muted font-weight-light"
									@click="formatProducts(item)">
									<i class="fa fa-eye" />
									{{ translate('view_details') }}
								</a>
							</p>
							<div
								class="row justify-content-center">
								<div class="col-auto">
									<p
										class="text-primary h5"
										style="text-decoration:line-through; text-align: center; opacity: 0.6; margin-bottom: 0">
										{{ item.attributes.packages[packagesStates[item.id]].actual_value }}
									</p>
								</div>
							</div>
							<div
								class="row justify-content-center">
								<div class="col-auto pr-2 pl-0">
									<div
										class="col-auto text-primary h3 font-weight-bold"
										style="padding: 0; margin-bottom: 0">
										{{ item.attributes.packages[packagesStates[item.id]].member_price }}
									</div>
								</div>
								<div
									v-if="item.attributes.packages[packagesStates[item.id]].has_active_promo"
									class="col-auto"
									style="padding: 0; margin-bottom: 0; margin-block: auto">
									<span
										class="badge badge-primary pointer text-uppercase"
										style="font-size:12px; margin-bottom: 0;">
										{{ translate('promo') }}
									</span>
								</div>
							</div>
							<div
								v-if="item.attributes.packages[packagesStates[item.id]].has_active_promo && item.attributes.packages[packagesStates[item.id]].old_member_price"
								class="pb-0">
								<span
									class="text-muted">
									{{ translate('non_pack_price', { amount: item.attributes.packages[packagesStates[item.id]].old_member_price }) }}
								</span>
							</div>
							<div
								v-if="item.attributes.packages[packagesStates[item.id]].importation_fee"
								class="pb-2">
								<div>
									<span
										class="font-weight-bold"
										style="font-size: 16px; color: white;">
										<span class="highlight-green">{{ item.attributes.packages[packagesStates[item.id]].importation_fee }}</span>
									</span>
								</div>
								<div style="margin-top: -5px">
									<span style="font-size: 12px; color: black;">
										{{ translate('importation_fee_notice') }}
									</span>
								</div>
							</div>
							<div
								class="pb-4"
								:class="item.attributes.packages.length > 1 ? '' : 'mb-2'">
								<span
									class="font-weight-bold font-italic highlight-yellow">
									{{ translate('savings', { amount: item.attributes.packages[packagesStates[item.id]].savings }) }}
								</span>
							</div>
							<b-button
								:disabled="validating || !!preparing || showFreeProductsModal"
								variant="primary"
								size="lg"
								block
								class="float-right"
								@click="listSelectableProducts(item, item.attributes.packages[packagesStates[item.id]].selectable_products.length > 0)">
								<i
									v-if="validating && item.id === selectedPack"
									class="fas  fa-spinner fa-spin mr-2" />{{ translate('select') }}
							</b-button>
						</li>
					</ul>
				</div>
				<div class="col" />
			</div>
		</template>
		<li
			v-else
			class="list-group-item w-100 h100 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="row h-100 w-100">
				<div class="col-12">
					<div class="h2 py-3">
						<i class="fa fa-fw fa-spinner fa-pulse" />
						<h4 class="mt-3">
							{{ translate('loading') }}
						</h4>
					</div>
				</div>
			</div>
		</li>
		<extra-products-modal
			:sections-import="selectableProductsOptions"
			:selectable-products.sync="selectableProducts"
			@close="showFreeProductsModal = false"
			@confirm="preparePackStorage()" />
		<register-pack-products-modal
			:pack-products="packProducts"
			:pack-id="selectedPack"
			@close="showRegisterPackProductsModal = false" />
	</div>
</template>
<script>
import GENERAL_INFO from '@/util/GeneralInformation';
import Products from '@/util/Products';
import RegisterPackProductsModal from '@/components/RegisterPackProductsModal';
import ExtraProductsModal from '@/components/ExtraProductsModal';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import EventBus from '@/util/eventBus';
import CommonMix from './mixins/Common';
import PackMix from '@/views/Register/mixins/Package';
import { Purchase, Products as productMessages, AccountTransferFee } from '@/translations';
import store from './store';
import SwitchToggle from '@/components/Switch';

export default {
	name: 'AccountTransferOption',
	messages: [Purchase, productMessages, AccountTransferFee],
	components: {
		RegisterPackProductsModal,
		ExtraProductsModal,
		SwitchToggle,
	},
	mixins: [CommonMix, DocumentTitle, PackMix, WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			showFreeProductsModal: false,
			showRegisterPackProductsModal: false,
			packs: new GENERAL_INFO(),
			accountFee: new Products(),
			selectableProductsList: [],
			selectedPack: '',
			packProducts: [],
			toggleStates: {},
			packagesStates: {},
		};
	},
	computed: {
		selectableProductsOptions() {
			try {
				return this.selectableProductsList;
			} catch (e) {
				return [];
			}
		},
		packsData() {
			try {
				return this.packs.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		accountFeeData() {
			try {
				return this.accountFee.data.response.data.data[0];
			} catch (error) {
				return [];
			}
		},
		loading() {
			return !!this.packs.data.loading || !!this.accountFee.data.loading;
		},
	},
	watch: {
		async country() {
			const currentCartId = this.cartId;
			this.removeInfo();
			await this.setNewCartId(currentCartId);
			store.dispatch('saveCountry', this.country);
		},
		purchase: {
			deep: true,
			handler() {
				this.showRegisterPackProductsModal = false;
			},
		},
		showRegisterPackProductsModal(newVal) {
			if (newVal) {
				EventBus.$emit('showRegisterPackProductsModal');
			}

			if (!newVal) {
				EventBus.$emit('hideRegisterPackProductsModal');
			}
		},
		showFreeProductsModal(show) {
			EventBus.$emit(show ? 'showFreeProductsModal' : 'hideFreeProductsModal');
		},
		packsData: {
			handler(newData) {
				// Initialize toggle states when packsData changes
				newData.forEach((item) => {
					this.$set(this.toggleStates, item.id, false);
					this.$set(this.packagesStates, item.id, 0);
				});
			},
			immediate: true, // Run handler immediately on component creation
		},
	},
	async created() {
		this.packs.getTransferFeePacks();
		this.accountFee.getAccountTransferFee(this.country);
	},
	methods: {
		async resetIfUserHasChanged() {
			const storedUser = store.getters.getRegisterUserId();
			if (storedUser !== 0 && storedUser !== this.$user.details().id) {
				// Get selected position
				const payload = this.getRegisterPositionValues();
				// Remove old information
				await store.dispatch('removeInfo');
				// Update position for register
				try {
					await this.$store.dispatch('position/registerInPosition', payload);
					await store.dispatch('setPositionForRegister', payload);
				} catch (error) {
					if (process.env.NODE_ENV !== 'production') {
						console.warn(error);
					}
				}
			}
			store.dispatch('saveUserId', this.$user.details().id);
		},
		handlePackToggle(itemID) {
			this.$set(this.packagesStates, itemID, Number(this.toggleStates[itemID]));
		},
		formatProducts(item) {
			this.selectedPack = item.id;
			this.packProducts = item.attributes.packages[this.packagesStates[item.id]].products_pack;
			this.showRegisterPackProductsModal = true;
		},
		preparePackStorage() {
			const packageCodeName = this.selectedPack;
			this.clearValidationError(true);
			this.preparing = true;
			let payload = {};
			payload.product = {
				package_code_name: packageCodeName,
				package_option: this.packagesStates[packageCodeName],
				is_pack: 1,
				products: {},
				selectable_products: this.selectableProducts,
			};

			payload = { step: this.currentValidation, ...payload };
			this.validatePackStep(payload).then((response) => {
				const { cart_id: cartId } = response.response;
				this.saveCart(cartId);
				this.saveProductStep(payload).then(() => {
					this.$router.push({
						name: 'TransferFeeConfirmation',
						params: { is_pack: 1, storedCartId: cartId, package_code_name: packageCodeName },
					});
				});
			}).catch(() => {}).finally(() => { this.preparing = false; });
		},
		prepareFeeStorage() {
			this.clearValidationError(true);
			this.preparing = true;

			const payload = { step: this.currentValidation };
			this.validateFeeStep(payload).then((response) => {
				const { cart_id: cartId } = response.response;
				this.saveCart(cartId);
				this.saveProductStep(payload).then(() => {
					this.$router.push({
						name: 'TransferFeeConfirmation',
					});
				});
			}).catch(() => {}).finally(() => { this.preparing = false; });
		},
		listSelectableProducts(item, showModal) {
			this.selectedPack = item.id;
			if (showModal) {
				this.selectableProductsList = item.attributes.packages[this.packagesStates[item.id]].selectable_products;
				this.showFreeProductsModal = true;
			} else {
				this.selectableProducts = {};
				this.preparePackStorage();
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!vm.$user.details().pay_transfer_account_fee) {
				vm.$router.replace({ name: 'MainHome' });
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('TransferFee')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			if (this.canLeave || !this.$user.details().pay_transfer_account_fee) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		} else if (to.name.includes('Home')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		}
	},
};
</script>
